<script>
  import { mapGetters } from "vuex";
  export default {
    data() {
      return {
        showModal: false,
        lastRoute: null,
        options: [
          {
            to: { name: "EditCompetition" },
            name: "Editar",
            icon: "edit_pencil",
            needId: true,
          },
          {
            to: { name: "CreateCompetition" },
            name: "Crear",
            icon: "plus",
          },
          {
            to: { name: "ListCompetition" },
            name: "Listar concursos",
            icon: "list",
          },
        ],
      };
    },
    computed: {
      ...mapGetters("user", ["getProfile"]),
      ...mapGetters("competitions", ["getCompetition"]),
      userIsAdmin() {
        return this.getProfile.role === "superadmin";
      },
      competitionActive() {
        return this.$route.params?.id;
      },
      requireBack() {
        return !this.$route.params?.year;
      },
    },
    methods: {
      handleOpenAndCloseModal() {
        this.showModal = !this.showModal;
      },
      gotToOptionSelected(option) {
        this.lastRoute = this.$router.currentRoute || null;
        this.$router.push(option.to, { params: option.needId ? { id: this.competitionActive } : {} });
        this.showModal = false;
      },
      handleClickOutside(event) {
        const modal = this.$refs.modal;
        if (modal && !modal.contains(event.target)) {
          this.showModal = false;
        }
      },
      backToLastPage() {
        if (!this.lastRoute) {
          this.$router.push({ name: "competition" });
          return;
        }
        const { name, params } = this.lastRoute;
        this.$router.push({ name, params });
      },
    },
    mounted() {
      document.addEventListener("click", this.handleClickOutside);
    },
    beforeDestroy() {
      document.removeEventListener("click", this.handleClickOutside);
    },
  };
</script>

<template>
  <section class="competitions">
    <div class="competitions__containerMenu">
      <p class="competitions__title">CONCURSOS</p>
      <div class="competitions__buttonBack" @click="backToLastPage" v-if="requireBack">
        <iconic name="close" class="competitions__plus" />
      </div>
      <div :class="`competitions__iconsContainer ${showModal ? 'competitions__iconsContainer--active' : ''}`" v-if="userIsAdmin && !requireBack" @click.stop="handleOpenAndCloseModal">
        <iconic name="verticalDots" class="competitions__dots" />
      </div>
      <transition name="slide-fade">
        <div class="competitions__icons" ref="modal" v-if="showModal">
          <div class="competitions__option" v-for="option in options" :key="option.name" @click="gotToOptionSelected(option)">
            <iconic :name="option.icon" class="competitions__optionIcon" />
            <p>{{ option.name }}</p>
          </div>
        </div>
      </transition>
    </div>
    <router-view></router-view>
  </section>
</template>

<style lang="scss" scoped>
  .competitions {
    $diagonal: 15deg;
    $radiusItems: $radius-4;
    @include Flex(column);
    width: 100%;
    max-width: 1440px;
    gap: $space-16;
    padding-top: $space-20;
    &__containerMenu {
      @include Flex(row, space-between);
      position: relative;
      width: 100%;
    }
    &__title {
      font-family: $sec_font;
      font-size: 32px;
      color: $primary;
      font-weight: bold;
    }
    &__option {
      @include Flex(row, flex-start);
      gap: $space-8;
      width: 100%;
      padding: $space-6;
      cursor: pointer;
      border-radius: $radius-8;
      transition: all 0.4s ease;
      &:hover {
        background-color: #f5f5f5;
      }
    }
    &__iconsContainer {
      @include Flex(row);
      width: 40px;
      height: 40px;
      border-radius: $radius-8;
      padding: $space-8 0;
      cursor: pointer;
      transition: all 0.4s ease;
      &:hover {
        background-color: #f5f5f5;
      }
      &--active {
        background-color: #f5f5f5;
      }
    }
    &__dots {
      font-size: 25px;
      color: #5b595c;
    }
    &__icons {
      @include Flex(column, space-between, flex-start);
      position: absolute;
      width: 190px;
      gap: $space-12;
      padding: $space-4;
      border-radius: $radius-8;
      background: #fff;
      right: 0;
      bottom: -138px;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
      z-index: 1;
    }
    &__plus {
      padding: 0.5rem;
      background: $primary;
      border-radius: 50%;
      color: #fff;
      cursor: pointer;
      transition: all 0.4s ease;
      &:hover {
        background-color: rgba(255, 26, 75, 1);
      }
    }
    .slide-fade-enter-active {
      transition: transform 0.2s ease-out, opacity 0.1s ease-out;
    }

    .slide-fade-enter,
    .slide-fade-leave-to {
      transform: translateY(-20px);
      opacity: 0;
    }

    .slide-fade-enter-to {
      transform: translateY(0);
      opacity: 1;
    }

    .slide-fade-leave-active {
      transition: transform 0.2s cubic-bezier(1, 0.5, 0.8, 1), opacity 0.1s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .slide-fade-leave-from {
      transform: translateY(0);
      opacity: 1;
    }

    .slide-fade-leave-to {
      transform: translateY(-20px);
      opacity: 0;
    }
  }
</style>
